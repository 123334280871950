@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@200;400;500;600;700&display=swap);
@charset "UTF-8";

.main .socials ul {
    display: flex;
    list-style: none;
    align-items: center;
    margin: auto;
    padding: 0;
    font-size: 1rem;
}

.main .socials i {
    color: rgb(104, 82, 82);
}

.main .socials li {
    padding-right: 25px;
}

.main .socials i:hover {
    color: #f0c0ce;
    transition: .5s;
    cursor: pointer;
}

.hello {
    padding-right: 0.25em;
}

.name {
    display: flex;
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;  
    font-weight: 700;
}

.letter, .emoji {
    color: #f0c0ce;
    transition: transform 1s;
    cursor: pointer;
}

.letter {
    text-shadow: 2px 2px #ca718c;
}

.emoji {
    padding-left: 0.25em;
}

.letter:hover, .emoji:hover {
    transform: translateY(-1rem);
    cursor: pointer;
}

.info p {
    text-align: justify;
}

a, a:visited {
    text-decoration: none;
    color: rgb(104, 82, 82);
    font-weight: 600;
}

a:hover {
    color: #f0c0ce;;
    transition: ease 0.2s;
}


/* responsive media query */

/* phone */
@media (max-width: 600px) {
    .name {
        font-size: 1.2rem;
    }
    .main p {
        font-size: 0.8rem;
    }
    .main .socials ul {
        font-size: 0.8rem;
        
    }
    .main .socials li {
        padding-right: 15px;
    }
}

/* portrait tablets + large phones */
@media (min-width: 600px) {
    .name {
        font-size: 2rem;
    }
}

/* landscape tablets */
@media (min-width: 768px) {
    .name {
        font-size: 2.5rem;
    }
    .main p {
        font-size: 0.5rem;
    }
}

/* laptops/desktops */
@media (min-width: 992px) {
    .main p {
        font-size: 1rem;
    }
}
.nav {
    float: right;
    padding-right: 2.5rem;
}

.d3l {
    font-size: 1rem;
}

.nav ul {
    list-style: none;
    margin: 0;
    padding-top: 2rem;
    width: 50%;
    height: 100%;
}

.nav li, .d3l {
    padding-top: 0.5rem;
}

.nav a {
    text-decoration: none;
    font-weight: 700;
    position: relative;
}

.nav img {
    --outline: 3px solid rgb(255, 250, 246);
    outline: var(--outline);
    border: var(--outline);
    height: auto;
    border-radius: 50%;
    transition: transform ease 0.25s;
  }

.nav img:hover,
.nav img:focus {
    outline: var(--outline);
    transform: scale(1.1);
}

.nav img.active {
    --pink: #f0c0ce;
    --outline: 3px solid var(--pink);
    color: var(--pink);
    outline: var(--outline);
    border: var(--outline);
}

.nav li a {
    color: rgb(107, 87, 86);
    position: relative;
    text-decoration: none;
}

.nav li a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.8rem;
    background-color: #f0c0ce;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.nav li a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.nav li a.active::before {
    width: 100%;
    height: 0.8rem;
    background-color: #f0c0ce;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(1);
}

/* phone */
@media (min-width: 0px) {
    nav {
        padding-left: 5rem;
    }
    .nav li {
        font-size: 1.1rem;
    }
    .nav img {
        width: 6rem;
    }

    .d3l {
        width: 100%;
        padding-left: 1.5rem;
    }
}

@media (min-width: 600px) {
    .nav li {
        font-size: 1.5rem;
    }

    .nav img {
        width: 7rem;
    }

    .d3l {
        width: 100%;
        padding-left: 2rem;
    }
}


/* PROJECTS */
@keyframes float {
	0% {
		transform: translatey(0%);
	}
	50% {
		transform: translatey(-1rem);
	}
	100% {
		transform: translatey(0%);
	}
}

.projects {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}

.gallery {
    width: 75%;
    padding-right: 2rem;
    box-sizing: border-box;
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}

.box {
    margin: 0 1.5rem 1.5rem 0;
    flex: 25% 1;
    max-width: 11rem;
    position: relative;
    float: left;
}
  
.box img {
    vertical-align: middle;
    width: 100%;
    border-radius: 20%;
    transition: .1s transform;
    transform: translateZ(0); 
  }
  
.box:hover {
    z-index: 1;
  }
  
.box:hover img {
    transform: scale(1.5,1.5);
    transition: .3s transform;
    opacity: 0.3;
    height: auto;
  }
  
.box .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
  }

.box:hover .overlay {
    opacity: 1;
  }
  
.project-title {
    font-size: 1.3rem;
    font-weight: 600;
}

.tools {
    font-size: 1rem;
}

.description {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 1em;
    color: rgb(104, 82, 82);
    overflow-wrap: anywhere;
    background: rgb(255, 255, 255, 0.5)
}

.gallery .fab {
    color: rgb(104, 82, 82);
    display: inline-flex;
    padding-right: 1rem;
    font-size: 2rem;
}

.gallery .fab:hover{
    color: #f0c0ce;;
    transition: ease 0.2s;
}
  
.cf:before, .cf:after {
    display: table;
    content: "";
    line-height: 0;
  }
  
  .cf:after {
    clear: both;
  }

@keyframes fillLeft {
    100% {
        right: 100%;
    }
}

@keyframes fillTop {
    100% {
        top: 100%;
    }
}

@keyframes fillLeftOdd {
    100% {
        left: 100%;
    }
}

/* responsive media query */

@media (min-width: 0px) {
    .gallery {
        width: 100%;
    }
    .box {
        flex: 50% 1;
    }
    .overlay .project-title{
        font-size: 1rem;
    }
    .overlay .tools {
        font-size: 1rem;
    }
    .overlay .description {
        font-size: 1rem;
    }
}

@media (min-width: 992px) {
    .gallery {
        width: 95%;
    }
}

/* laptops/desktops */
@media (min-width: 1283px) {
    .gallery {
        width: 75%;
    }
}
.experience {
    padding-bottom: 15rem;
    color: rgb(104, 82, 82);
}

.vertical-timeline-element--work h2 {
    color: #f0c0ce;
}

.vertical-timeline-element--work {
    transition: width 0.3s ease, cursor 0.3s ease;
}

.vertical-timeline-element--work:hover {
    cursor: pointer;
    width: 55%;
}

@media only screen and (min-width: 0px) {
    .vertical-timeline-element--work {
        width: 90%;
    }
    .vertical-timeline-element--work:hover {
        width: 95%;
    }
    .experience img {
        width: 2.5rem;
        border-radius: 75%;
        float: center;
    }
    /* endregion */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .vertical-timeline-element--work {
        width: 80%;
    }
    .vertical-timeline-element--work:hover {
        width: 85%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .vertical-timeline-element--work {
        width: 65%;
    }
    .vertical-timeline-element--work:hover {
        width: 67%;
    }
}

@media only screen and (min-width: 1500px) {
    .vertical-timeline-element--work {
        width: 60%;
    }
    .vertical-timeline-element--work:hover {
        width: 62%;
    }
}

@media only screen and (min-width: 1750px) {
    .vertical-timeline-element--work {
        width: 50%;
    }
    .vertical-timeline-element--work:hover {
        width: 55%;
    }
}
/* scrollbar */
html {
    scroll-behavior: smooth;
}

 ::-webkit-scrollbar {
    width: 10px;
}

 ::-webkit-scrollbar-track {
    background-color: transparent;
}

 ::-webkit-scrollbar-thumb {
    background-color: #f0c0ce;
    border-radius: 10px;
}
/* endregion */

.melody {
    position: fixed; 
    bottom: 0px; 
    right: 0px;
}

body {
    margin: 0;
    color: rgb(104, 82, 82);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    background: rgb(248, 238, 238);
    overflow: scroll
}
  
.content {
    padding-top: 15%;
}

.left {
    float: left;
    width: 42%;
}

.right {
    float: right;
    width: 58%;
}

h1 {
    width: 80%;
}

p {
    width: 60%;
    text-align: justify;
    word-spacing:-2px;
}

@media only screen and (min-width: 0px) {
    h1 {
        font-size: 1.5rem;
    }
    h2 {
        font-size: 1.2rem;
    }
    h3 {
        font-size: 1rem;
    }
    p {
        width: 85%;
        font-size: 1rem;
        text-align: left;
        word-spacing: 0px;
    }
    .content {
        padding-top: 15%;
    }
    .melody {
        width: 5rem;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    .melody {
        width: 7rem;
        padding: 0;
    }
}

@media (min-width: 1200px) {
    body {
        font-size: 1rem;
    }
    p {
        width: 50%;
    }
    .content {
        padding-top: 10%;
    }
    .melody {
        width: 10rem;
        padding: 0;
    }
}

