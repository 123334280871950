@import "main.css";
@import "navbar.css";
@import "projects.css";
@import "experience.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@200;400;500;600;700&display=swap');


/* scrollbar */
html {
    scroll-behavior: smooth;
}

 ::-webkit-scrollbar {
    width: 10px;
}

 ::-webkit-scrollbar-track {
    background-color: transparent;
}

 ::-webkit-scrollbar-thumb {
    background-color: #f0c0ce;
    border-radius: 10px;
}
/* endregion */

.melody {
    position: fixed; 
    bottom: 0px; 
    right: 0px;
}

body {
    margin: 0;
    color: rgb(104, 82, 82);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    background: rgb(248, 238, 238);
    overflow: scroll
}
  
.content {
    padding-top: 15%;
}

.left {
    float: left;
    width: 42%;
}

.right {
    float: right;
    width: 58%;
}

h1 {
    width: 80%;
}

p {
    width: 60%;
    text-align: justify;
    word-spacing:-2px;
}

@media only screen and (min-width: 0px) {
    h1 {
        font-size: 1.5rem;
    }
    h2 {
        font-size: 1.2rem;
    }
    h3 {
        font-size: 1rem;
    }
    p {
        width: 85%;
        font-size: 1rem;
        text-align: left;
        word-spacing: 0px;
    }
    .content {
        padding-top: 15%;
    }
    .melody {
        width: 5rem;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    .melody {
        width: 7rem;
        padding: 0;
    }
}

@media (min-width: 1200px) {
    body {
        font-size: 1rem;
    }
    p {
        width: 50%;
    }
    .content {
        padding-top: 10%;
    }
    .melody {
        width: 10rem;
        padding: 0;
    }
}
