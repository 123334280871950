.nav {
    float: right;
    padding-right: 2.5rem;
}

.d3l {
    font-size: 1rem;
}

.nav ul {
    list-style: none;
    margin: 0;
    padding-top: 2rem;
    width: 50%;
    height: 100%;
}

.nav li, .d3l {
    padding-top: 0.5rem;
}

.nav a {
    text-decoration: none;
    font-weight: 700;
    position: relative;
}

.nav img {
    --outline: 3px solid rgb(255, 250, 246);
    outline: var(--outline);
    border: var(--outline);
    height: auto;
    border-radius: 50%;
    transition: transform ease 0.25s;
  }

.nav img:hover,
.nav img:focus {
    outline: var(--outline);
    transform: scale(1.1);
}

.nav img.active {
    --pink: #f0c0ce;
    --outline: 3px solid var(--pink);
    color: var(--pink);
    outline: var(--outline);
    border: var(--outline);
}

.nav li a {
    color: rgb(107, 87, 86);
    position: relative;
    text-decoration: none;
}

.nav li a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.8rem;
    background-color: #f0c0ce;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.nav li a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.nav li a.active::before {
    width: 100%;
    height: 0.8rem;
    background-color: #f0c0ce;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(1);
}

/* phone */
@media (min-width: 0px) {
    nav {
        padding-left: 5rem;
    }
    .nav li {
        font-size: 1.1rem;
    }
    .nav img {
        width: 6rem;
    }

    .d3l {
        width: 100%;
        padding-left: 1.5rem;
    }
}

@media (min-width: 600px) {
    .nav li {
        font-size: 1.5rem;
    }

    .nav img {
        width: 7rem;
    }

    .d3l {
        width: 100%;
        padding-left: 2rem;
    }
}

