.experience {
    padding-bottom: 15rem;
    color: rgb(104, 82, 82);
}

.vertical-timeline-element--work h2 {
    color: #f0c0ce;
}

.vertical-timeline-element--work {
    transition: width 0.3s ease, cursor 0.3s ease;
}

.vertical-timeline-element--work:hover {
    cursor: pointer;
    width: 55%;
}

@media only screen and (min-width: 0px) {
    .vertical-timeline-element--work {
        width: 90%;
    }
    .vertical-timeline-element--work:hover {
        width: 95%;
    }
    .experience img {
        width: 2.5rem;
        border-radius: 75%;
        float: center;
    }
    /* endregion */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .vertical-timeline-element--work {
        width: 80%;
    }
    .vertical-timeline-element--work:hover {
        width: 85%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .vertical-timeline-element--work {
        width: 65%;
    }
    .vertical-timeline-element--work:hover {
        width: 67%;
    }
}

@media only screen and (min-width: 1500px) {
    .vertical-timeline-element--work {
        width: 60%;
    }
    .vertical-timeline-element--work:hover {
        width: 62%;
    }
}

@media only screen and (min-width: 1750px) {
    .vertical-timeline-element--work {
        width: 50%;
    }
    .vertical-timeline-element--work:hover {
        width: 55%;
    }
}